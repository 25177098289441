import { takeLatest, fork, call } from 'redux-saga/effects';

import { resetPasswordApi } from './api';
import { RESET_PASSWORD } from './constant';

function* resetPasswordSaga(): any {
    try {
        // const response = yield call(forgetPasswordApi);
        // console.log('response = ', response);
    } catch (err) {
        console.log('err = ', err);
    }
}

export default function*(): any {
    yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
}
