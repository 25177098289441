import { takeLatest, fork, call } from 'redux-saga/effects';

import { logoutApi } from './api';
import { LOGOUT } from './constant';

function* logoutSaga(): any {
    try {
        // const response = yield call(forgetPasswordApi);
        // console.log('response = ', response);
    } catch (err) {
        console.log('err = ', err);
    }
}

export default function*(): any {
    yield takeLatest(LOGOUT, logoutSaga);
}
