import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Hero from '../../components/Hero/Hero';

import './style.scss';

export default class extends React.Component {
    public componentDidMount() {}
    public render() {
        return (
            <section className="what-section spad set-bg">
                <div className="container">
                    <div className="section-title text-white">
                        <p>This is</p>
                        <h2>What I do</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="what-card">
                                <img
                                    src={require('./img/caiqipai.png')}
                                    alt=""
                                />
                                <div className="wc-text">
                                    <h5>Native App</h5>
                                    <p>
                                        Use React Native, build commercial
                                        native app for both iOS and Android
                                        platform.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="what-card">
                                <img src={require('./img/thai02.png')} alt="" />
                                <div className="wc-text">
                                    <h5>Website</h5>
                                    <p>
                                        Use React, build single page website
                                        with better user experience
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="what-card">
                                <img
                                    src={require('./img/cognito02.png')}
                                    alt=""
                                />
                                <div className="wc-text">
                                    <h5>Backend and AWS</h5>
                                    <p>
                                        Use Node.js and AWS, build a backend
                                        service
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
