import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Hero from '../../components/Hero/Hero';

import './style.scss';

export default class extends React.Component {
    public componentDidMount() {}
    public render() {
        return (
            <section className="about-intro-section spad">
                <div className="container">
                    <div className="section-title">
                        <p>Viola</p>
                        <h2>Who am I</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-intro-pic">
                                <img src={require('./img/me01.png')} alt="" />
                                {/*<a*/}
                                {/*href="https://www.youtube.com/watch?v=4T31zr0fsvs"*/}
                                {/*className="video-play video-popup"*/}
                                {/*>*/}
                                {/*<i className="fa fa-play" />*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="col-lg-6 about-intro-text">
                            {/*<ul>*/}
                            {/*<li>As a developer, enthusiastic about new*/}
                            {/*technologies.</li>*/}
                            {/*<li>As a developer, enthusiastic about new*/}
                            {/*technologies.</li>*/}
                            {/*</ul>*/}
                            <p className="intro-text">
                                As a developer, enthusiastic about learning new
                                technologies.
                            </p>
                            <p className="intro-text">
                                As a scuba diver, like to explore the world
                                under another horizon
                            </p>
                            <p className="intro-text">
                                As a traveller, passionate about discover new
                                places and cultures
                            </p>
                            <p className="intro-text">
                                As a mortal, hates to waste time, always wants
                                to find something new to try
                            </p>
                            {/*<div className="site-btn sb-c3">Read more</div>*/}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
