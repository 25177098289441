import { fork } from 'redux-saga/effects';

// import loot from '../components/Loot/saga';
// import wallet from '../components/Wallet/saga';

import lazy from '../components/Lazy/saga';
import signUp from '../components/SignUp/saga';
import verifyEmail from '../components/VerifyEmail/saga';
import signIn from '../components/SignIn/saga';
import changePassword from '../components/ChangePassword/saga';
import forgetPassword from '../components/ForgetPassword/saga';
import resetPassword from '../components/ResetPassword/saga';
import logout from '../components/Logout/saga';

export default function*(): any {
    // yield fork(loot);
    // yield fork(wallet);
    yield fork(lazy);
    yield fork(signUp);
    yield fork(verifyEmail);
    yield fork(signIn);
    yield fork(changePassword);
    yield fork(forgetPassword);
    yield fork(resetPassword);
    yield fork(logout);
}
