import { FORGET_PASSWORD } from './constant';

const INITIAL_STATE = {
    test: 123,
};

export default (state = INITIAL_STATE, action: { type: string }) => {
    switch (action.type) {
        case FORGET_PASSWORD:
            return { ...state, test: 456 };
        default:
            return state;
    }
};
