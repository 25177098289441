import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Hero from '../../components/Hero/Hero';
import Feature from '../../components/Feature/Feature';
import Service from '../../components/Service/Service';
import About from '../../components/About/About';
import Milestone from '../../components/Milestone/Milestone';
import Banner from '../../components/Banner/Banner';

export default class extends React.Component {
    public componentDidMount() {
        console.log('this = ', this);
    }
    public render() {
        return (
            <div>
                <Hero />
                <Feature />
                {/*<Service />*/}
                {/*<About />*/}
                {/*<Milestone />*/}
                {/*<Banner />*/}
            </div>
        );
    }
}
