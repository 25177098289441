import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';

import Home from '../../screens/Home/Home';
import AboutUs from '../../screens/AboutUs/AboutUs';
import Project from '../../screens/Project/Project';
import Contact from '../../screens/Contact/Contact';
import News from "../../screens/News/News";

const RouteContainer = posed.div({
    enter: { opacity: 1, delay: 500, beforeChildren: true },
    exit: { opacity: 0 },
});

export default class extends React.Component<any, any> {
    public componentDidMount() {}
    public render() {
        const { location } = this.props;
        return (
            <PoseGroup>
                <RouteContainer key={location.key || 'aaaaaa'}>
                    <Switch location={location}>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/about" component={AboutUs} />
                        <Route exact path="/project" component={Project} />
                        <Route exact path="/news" component={News} />
                        <Route exact path="/contact" component={Contact} />
                    </Switch>
                </RouteContainer>
            </PoseGroup>
        );
    }
}
