import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';

import './style.scss';

export default class extends React.Component<any, any> {
    public componentDidMount() {}
    public render() {
        const { title } = this.props;
        return (
            <section className="page-top-section set-bg">
                <div className="container">
                    <h2>{title || 'About Us'}</h2>
                </div>
            </section>
        );
    }
}
