import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import PageTop from '../../components/PageTop/PageTop';
import Contact from '../../components/Contact/Contact';

export default class extends React.Component {
    public componentDidMount() {
        console.log('this = ', this);
    }
    public render() {
        return (
            <div>
                <PageTop title={'Contact'}/>
                <Contact />
            </div>
        );
    }
}
