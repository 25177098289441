import { VERIFY_EMAIL } from './constant';

const INITIAL_STATE = {
    test: 123,
};

export default (state = INITIAL_STATE, action: { type: string }) => {
    switch (action.type) {
        // case SIGN_UP:
        //     return { ...state, test: 456 };
        default:
            return state;
    }
};
