import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Link } from 'react-router-dom';
// import { Router, Route, hashHistory } from 'react-router';

// import App from './components/App/App';
import Lazy from './components/Lazy/Lazy.container';
import store from './store/createStore';
import SignUp from './components/SignUp/SignUp.container';
import VerifyEmail from './components/VerifyEmail/VerifyEmail.container';
import SignIn from './components/SignIn/SignIn.container';
import ChangePassword from './components/ChangePassword/ChangePassword.container';
import ForgetPassword from './components/ForgetPassword/ForgetPassword.container';
import ResetPassword from './components/ResetPassword/ResetPassword.container';
import Logout from './components/Logout/Logout.container';
import ResendConfirmationCode from './components/ResendConfirmationCode/ResendConfirmationCode';
import Header from './components/Header/Header';
import Home from './screens/Home/Home';
import FooterTop from "./components/FooterTop/FooterTop";
import Footer from "./components/Footer/Footer";
import AboutUs from "./screens/AboutUs/AboutUs";

import posed, { PoseGroup } from 'react-pose';
import App from './services/App'

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <div>
                <App />
                {/*<Route exact path="/" component={Home} />*/}
                {/*<Route path="/about" component={AboutUs} />*/}
                {/*<Route path="/lazy" component={Lazy} />*/}
                {/*<Route path="/signUp" component={SignUp} />*/}
                {/*<Route path="/verifyEmail" component={VerifyEmail} />*/}
                {/*<Route path="/resend" component={ResendConfirmationCode} />*/}
                {/*<Route path="/signIn" component={SignIn} />*/}
                {/*<Route path="/changePassword" component={ChangePassword} />*/}
                {/*<Route path="/forgetPassword" component={ForgetPassword} />*/}
                {/*<Route path="/resetPassword" component={ResetPassword} />*/}
                {/*<Route path="/logout" component={Logout} />*/}
                {/*<FooterTop />*/}
                <Footer />
            </div>
        </BrowserRouter>
    </Provider>,
    document.getElementById('index'),
);
