import { takeLatest, fork, call } from 'redux-saga/effects';

import { changePasswordApi } from './api';
import { CHANGE_PASSWORD } from './constant';

function* changePasswordSaga(): any {
    try {
        const response = yield call(changePasswordApi);
        console.log('response = ', response);
    } catch (err) {
        console.log('err = ', err);
    }
}

export default function*(): any {
    yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
}
