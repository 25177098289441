import * as React from 'react';
import Slider from 'nuka-carousel';
import posed from 'react-pose';

import './style.scss';
import { BrowserRouter, Route, Link } from 'react-router-dom';

const TitlePose = posed.h2({
    top: { y: -100, opacity: 1, transition: { duration: 500 } },
    bottom: { y: 100, opacity: 0 },
});
const ContentPose = posed.p({
    top: { y: -70, opacity: 1, transition: { duration: 500 } },
    bottom: { y: 70, opacity: 0 },
});
// const ContentPose2 = posed.p({
//     top: { y: -70, opacity: 1, transition: { duration: 500 }, color: '#00000' },
//     bottom: { y: 70, opacity: 0 },
// });
const ButtonPose = posed.a({
    top: { y: -70, opacity: 1, transition: { duration: 500 } },
    bottom: { y: 70, opacity: 0 },
});

export default class extends React.Component<any, any> {
    public state = {
        titleAnimation: 'bottom',
        contentAnimation: 'bottom',
        buttonAnimation: 'bottom',
    };
    public componentDidMount() {
        setTimeout(() => {
            this.setState({
                titleAnimation: 'top',
            });
        }, 2000);
        setTimeout(() => {
            this.setState({
                contentAnimation: 'top',
            });
        }, 2300);
        setTimeout(() => {
            this.setState({
                buttonAnimation: 'top',
            });
        }, 2600);
    }
    public render() {
        const {
            titleAnimation,
            contentAnimation,
            buttonAnimation,
        } = this.state;

        return (
            <section className="hero-section">
                <div className="hero-slider owl-carousel">
                    <Slider
                        autoplay={true}
                        autoplayInterval={5000}
                        speed={500}
                        pauseOnHover={false}
                        wrapAround={true}
                        dragging={false}
                        renderCenterLeftControls={() => null}
                        renderCenterRightControls={() => null}
                    >
                        <div className="hs-item set-bg hs-bg2">
                            <div className="container">
                                <TitlePose pose={titleAnimation}>
                                    Challenge yourself is the only path which
                                    leads to growth.
                                </TitlePose>
                                <ContentPose pose={contentAnimation}>
                                    We commit to finding and building the
                                    leaders who will work to make this vision a
                                    reality. Work smarter, less harder.
                                </ContentPose>
                                <ContentPose pose={contentAnimation}>
                                    Let us all choose to be the leaders we wish
                                    we had
                                </ContentPose>
                                <div className="clearfix" />
                                <ButtonPose
                                    // href=""
                                    className="site-btn sb-c1"
                                    pose={buttonAnimation}
                                >
                                    <Link className="test" to="/about">
                                        Read More
                                    </Link>
                                </ButtonPose>
                            </div>
                        </div>
                        <div className="hs-item set-bg hs-bg1">
                            <div className="container">
                                <TitlePose pose={titleAnimation}>
                                    Get busy living, or get busy dying.
                                </TitlePose>
                                <ContentPose pose={contentAnimation}>
                                    Don't fear failure. Fear being in the exact
                                    same place next year as you are today.
                                </ContentPose>
                                <ContentPose pose={contentAnimation}>
                                    Life is a dream for wise, a game for the
                                    fool, a comedy for rich, a tragedy for the
                                    poor.
                                </ContentPose>
                                <div className="clearfix" />
                                <ButtonPose
                                    // href=""
                                    className="site-btn sb-c1"
                                    pose={buttonAnimation}
                                >
                                    <Link className="test" to="/about">
                                        Read More
                                    </Link>
                                </ButtonPose>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        );
    }
}
