import axios from 'axios';

export const signInApi = () => {
    // return axios.post(
    //     'https://q6q2684sri.execute-api.us-east-1.amazonaws.com/prod/user',
    //     {
    //         email: '456',
    //         password: '456',
    //     },
    // );
};
