import * as React from 'react';
import { Link } from 'react-router-dom';
import { Hub, Logger } from 'aws-amplify';

import './style.scss';

export default class extends React.Component<any, any> {
    public state = {
        activePage: 'home',
    };
    public componentDidMount() {
        console.log('this.props = ', this.props);
    }
    public render() {
        return (
            <header className="header-section">
                <div className="container">
                    <Link to="/" className="site-logo">
                        <img src={require('./img/logo01.png')} alt="logo" />
                    </Link>
                    <div className="nav-switch">
                        <div className="ns-bar" />
                    </div>
                    <div className="header-right">
                        <ul className="main-menu">
                            <li
                                className={
                                    this.state.activePage === 'home'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link
                                    to="/"
                                    onClick={() => {
                                        this.setState({
                                            activePage: 'home',
                                        });
                                    }}
                                >
                                    Home
                                </Link>
                            </li>
                            <li
                                className={
                                    this.state.activePage === 'about'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link
                                    to="/about"
                                    onClick={() => {
                                        this.setState({
                                            activePage: 'about',
                                        });
                                    }}
                                >
                                    About Me
                                </Link>
                            </li>
                            <li
                                className={
                                    this.state.activePage === 'project'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link
                                    to="/project"
                                    onClick={() => {
                                        this.setState({
                                            activePage: 'project',
                                        });
                                    }}
                                >
                                    Project
                                </Link>
                            </li>
                            {/*<li>*/}
                            {/*<Link to="/news">News</Link>*/}
                            {/*</li>*/}
                            <li
                                className={
                                    this.state.activePage === 'contact'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <Link
                                    to="/contact"
                                    onClick={() => {
                                        this.setState({
                                            activePage: 'contact',
                                        });
                                    }}
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                        <div className="header-btns">
                            {/*<a href="#" className="site-btn sb-c1">*/}
                            {/*Support <span>24/7</span>*/}
                            {/*</a>*/}
                            {/*<a*/}
                                {/*href="https://auth01.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=kf0if7vstdfe4i07lb8cd0l36&redirect_uri=http://localhost:7777"*/}
                                {/*className="site-btn sb-c2"*/}
                            {/*>*/}
                                {/*Login*/}
                            {/*</a>*/}
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
