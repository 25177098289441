import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Hero from '../../components/Hero/Hero';

import './style.scss';

export default class extends React.Component<any, any> {
    public componentDidMount() {}
    public render() {
        const { variation } = this.props;

        return (
            <section
                className={
                    variation === 'white'
                        ? 'Services-section-white spad set-bg'
                        : 'Services-section-black spad set-bg'
                }
                // style={testStyle}
                // data-setbg={require('./img/service-bg.jpg')}
            >
                <div className="container">
                    <div
                        className={
                            variation === 'white'
                                ? 'section-title text-black'
                                : 'section-title text-white'
                        }
                    >
                        <p>The ultimate</p>
                        <h2>Tech Stack</h2>
                    </div>
                    <div className="row services-circle">
                        <div className="col-lg-5 col-md-6">
                            <ul
                                className={
                                    variation === 'white'
                                        ? 'service-list service-list-left text-black'
                                        : 'service-list service-list-left text-white'
                                }
                            >
                                <li>
                                    <div className="sl-icon">
                                        <img
                                            src={require('./img/react-native.png')}
                                            alt=""
                                        />
                                    </div>
                                    <div className="sl-content">
                                        <h4>React Native</h4>
                                        <p>
                                            Most powerful weapon now. Confident
                                            to build professional app from
                                            scratch with code push and reuseful
                                            components.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="sl-icon">
                                        <img
                                            src={require('./img/react.png')}
                                            alt=""
                                        />
                                    </div>
                                    <div className="sl-content">
                                        <h4>React</h4>
                                        <p>
                                            Build reuseful components for web
                                            and mobile along with Redux,
                                            Reselect and Redus-Saga.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="sl-icon">
                                        <img
                                            src={require('./img/jenkins.png')}
                                            alt=""
                                        />
                                    </div>
                                    <div className="sl-content">
                                        <h4>Jenkins</h4>
                                        <p>
                                            Use for CI/CD process. May upgrade
                                            to more advance way in the future.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-5 offset-lg-2 col-md-6">
                            <ul
                                className={
                                    variation === 'white'
                                        ? 'service-list service-list-right text-black'
                                        : 'service-list service-list-right text-white'
                                }
                            >
                                <li>
                                    <div className="sl-icon">
                                        <img
                                            src={require('./img/aws.png')}
                                            alt=""
                                        />
                                    </div>
                                    <div className="sl-content">
                                        <h4>AWS</h4>
                                        <p>
                                            Use Cognito to simplify the precess
                                            of authentication. Also use Almbda
                                            function to build serverless
                                            architecture.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="sl-icon">
                                        <img
                                            src={require('./img/node.png')}
                                            alt=""
                                        />
                                    </div>
                                    <div className="sl-content">
                                        <h4>Node.js</h4>
                                        <p>
                                            Use it to build back end services
                                            and to provide API for frond end
                                            application.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="sl-icon">
                                        <img
                                            src={require('./img/docker.png')}
                                            alt=""
                                        />
                                    </div>
                                    <div className="sl-content">
                                        <h4>Docker</h4>
                                        <p>
                                            Not only for testing. Along with
                                            Kubernetes and EC2, can build some
                                            interesting fault tolerance project
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="service-main-circle">
                            <img
                                src={require('./img/service-circle-pic.png')}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
