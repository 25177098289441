import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Hero from '../../components/Hero/Hero';

import './style.scss';

export default class extends React.Component {
    public componentDidMount() {}
    public render() {
        return (
            <section className="feature-section spad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 feature">
                            <img
                                src={require('./img/global.png')}
                                alt="#"
                            />
                            <h4>Global Mindset</h4>
                            <p />
                        </div>
                        <div className="col-md-4 feature">
                            <img
                                src={require('./img/target.png')}
                                alt="#"
                            />
                            <h4>Goal-oriented</h4>
                            <p />
                        </div>
                        <div className="col-md-4 feature">
                            <img
                                src={require('./img/motivation.png')}
                                alt="#"
                            />
                            <h4>Self-motivated</h4>
                            <p />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
