import { takeLatest, fork, call } from 'redux-saga/effects';

import { signUpApi } from './api';
import { SIGN_UP } from './constant';

// import {AmazonCognitoIdentity} from 'amazon-cognito-identity-js'


function* signUpSaga(): any {
    try {
        // const response = yield call(signUpApi);
        // console.log('response = ', response);
    } catch (err) {
        console.log('err = ', err);
    }
}

export default function*(): any {
    yield takeLatest(SIGN_UP, signUpSaga);
}
