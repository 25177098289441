import { combineReducers } from 'redux';

import lazy from '../components/Lazy/reducer';
import signUp from '../components/SignUp/reducer';
import verifyEmail from '../components/VerifyEmail/reducer';
import signIn from '../components/SignIn/reducer';
import changePassword from '../components/ChangePassword/reducer';
import forgetPassword from '../components/ForgetPassword/reducer';
import resetPassword from '../components/ResetPassword/reducer';
import logout from '../components/Logout/reducer';

const rootReducer = combineReducers({
    lazy,
    signUp,
    verifyEmail,
    signIn,
    changePassword,
    forgetPassword,
    resetPassword,
    logout,
});

export default rootReducer;
