import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Hero from '../../components/Hero/Hero';

// import './style.scss';
import PageTop from '../../components/PageTop/PageTop';

export default class extends React.Component {
    public componentDidMount() {}
    public render() {
        return (
            <div>
                <PageTop />
            </div>
        );
    }
}
