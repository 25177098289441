import axios from 'axios';

export const getLazy = () => {
    return axios.get('https://httpbin.org/get');
};

export const signUpApi = () => {
    // return axios.get(
    //     'https://8vtgupvdqc.execute-api.ap-southeast-1.amazonaws.com/default/greet',
    // );
    return axios.post(
        'https://q6q2684sri.execute-api.us-east-1.amazonaws.com/prod/user',
        {
            email: '456',
            password: '456',
        },
    );
};
