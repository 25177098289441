import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Hero from '../../components/Hero/Hero';

import './style.scss';

export default class extends React.Component<any, any> {
    public componentDidMount() {}
    public render() {
        const { data } = this.props;

        if (data && data.rendertType === 'left') {
            return (
                <section className="about-section spad">
                    <div className="container">
                        <div className="section-title">
                            <p>{data.smallTitle || 'the only one'}</p>
                            <h2>{data.title || 'We generate trust'}</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-5 about-img">
                                <img
                                    src={require(`./img/${data.imgName}`)}
                                    alt=""
                                />
                            </div>
                            <div className="col-lg-7 about-text">
                                <p>{data.description || ''}</p>
                                <ul className="about-list">
                                    <li>{data.point1 || ''}</li>
                                    <li>{data.point2 || ''}</li>
                                    <li>{data.point3 || ''}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            );
        } else {
            return (
                <section className="about-section spad">
                    <div className="container">
                        <div className="section-title">
                            <p>{data.smallTitle || 'the only one'}</p>
                            <h2>{data.title || 'We generate trust'}</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-7 about-text">
                                <p>{data.description || ''}</p>
                                <ul className="about-list">
                                    <li>{data.point1 || ''}</li>
                                    <li>{data.point2 || ''}</li>
                                    <li>{data.point3 || ''}</li>
                                </ul>
                            </div>
                            <div className="col-lg-5 about-img2">
                                <img
                                    src={require(`./img/${data.imgName}`)}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    }
}
