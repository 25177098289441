import * as React from 'react';
import posed from 'react-pose';

import './style.scss';

const Loader = posed.div({
    hidden: {
        opacity: 0,
        transition: { duration: 500, ease: 'easeOut' },
    },
    visible: {
        opacity: 1,
        // transition: { duration: 3000, ease: 'easeOut' },
    },
});

export default class extends React.Component<any, any> {
    public state = { isVisible: true, isLoading: true };

    public componentDidMount() {
        setTimeout(() => {
            this.setState(
                {
                    isVisible: false,
                },
                () => {
                    setTimeout(() => {
                        this.setState({
                            isLoading: false,
                        });
                    }, 500);
                },
            );
        }, 1000);
    }
    public render(): any {
        const { isVisible, isLoading } = this.state;
        return (
            <Loader
                className={isLoading ? 'preloader' : 'preloader-done'}
                pose={isVisible ? 'visible' : 'hidden'}
            >
                <div className="loader" />
            </Loader>
        );
    }
}
