import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import PageTop from '../../components/PageTop/PageTop';
import Intro from '../../components/Intro/Intro';
import What from '../../components/What/What';
import Service from '../../components/Service/Service';
import Testimonial from '../../components/Testimonial/Testimonial';

export default class extends React.Component {
    public componentDidMount() {
        console.log('this = ', this);
    }
    public render() {
        return (
            <div>
                <PageTop title={'About Me'}/>
                <Intro />
                <What />
                <Service variation={'white'}/>
                {/*<Testimonial />*/}
            </div>
        );
    }
}
