import * as React from 'react';
import { Route } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';

import Preloader from '../elements/Preloader/Preloader';
import Header from '../components/Header/Header';
import RouterConfig from '../services/router/config';

class App extends React.Component {
    public componentDidMount() {}
    public render() {
        return (
            <Route
                render={({ location }) => {
                    return (
                        <div>
                            <Preloader />
                            <Header />
                            <RouterConfig location={location} />
                        </div>
                    );
                }}
            />
        );
    }
}

export default hot(App);
