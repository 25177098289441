import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Hero from '../../components/Hero/Hero';

// import './style.scss';
import PageTop from '../../components/PageTop/PageTop';
import Service from '../../components/Service/Service';
import Price from '../../components/Price/Price';
import Add from '../../components/Add/Add';
import About from '../../components/About/About';

const config = {
    caiqipai: {
        smallTitle: 'poker game',
        title: 'Cai Qi Pai',
        rendertType: 'left',
        description:
            'A native app game platform integrates with other vendors. The purpose of Home page is to let users feel they are in the game already. This project is very urgent and really challenge the coding speed.',
        point1: 'Tech Stack: React, React Native, Code Push',
        point2: 'Build project from scratch to production alone',
        point3: 'Agile method. Quick Development within 2 month.',
        imgName: 'caiqipai.png',
    },
    heleLottery: {
        smallTitle: 'lottery game',
        title: 'H Lottery',
        rendertType: 'right',
        description:
            'The most profitable product in the company and the most important project in the year. With very good error handling and very professional project structure.',
        point1: 'Tech Stack: React, React Native, Redux, Reselect, Redux Saga',
        point2:
            'Built highly-reusable components by redux, redux-saga and reselect',
        point3: `API cancellation and API timeout functionality with decorator generator function and interceptor.`,
        imgName: 'hele99.png',
    },
    heleThai: {
        smallTitle: 'game portal website',
        title: 'H Thai',
        rendertType: 'left',
        description:
            'The website is a game platform for Thai market. It uses templating engine to render multi-language support websites. Middleware use Node.js to communicate with Java micro-service based backend.',
        point1: 'Tech Stack: Node.js, Webpack, Bootstrap, Handlebars',
        point2: 'Team lead role responsible for both front end and middleware',
        point3: 'Third-party vendor API integration',
        imgName: 'thai02.png',
    },
};

export default class extends React.Component {
    public componentDidMount() {}
    public render() {
        return (
            <div>
                <PageTop title="Project" />
                <About data={config.caiqipai} />
                <About data={config.heleLottery} />
                <About data={config.heleThai} />
                {/*<Service />*/}
                {/*<Price />*/}
                {/*<Add />*/}
            </div>
        );
    }
}
