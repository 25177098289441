import { takeLatest, fork, call } from 'redux-saga/effects';

import { signInApi } from './api';
import { SIGN_IN } from './constant';

function* signInSaga(): any {
    try {
        const response = yield call(signInApi);
        console.log('response = ', response);
    } catch (err) {
        console.log('err = ', err);
    }
}

export default function*(): any {
    yield takeLatest(SIGN_IN, signInSaga);
}
