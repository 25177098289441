import * as React from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import Hero from '../../components/Hero/Hero';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faLinkedin,
    faGithub,
    faSkype,
} from '@fortawesome/free-brands-svg-icons';
import {
    faCoffee,
    faMailBulk,
    faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import {} from '@fortawesome/free-regular-svg-icons';

import './style.scss';

export default class extends React.Component {
    public componentDidMount() {}
    public onSubmit = () => {
        alert('This function is under development');
    };
    public render() {
        return (
            <section className="contact-section spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 contact-text">
                            <h4 className="contact-title">Contact me</h4>
                            <p>
                                If you are interested in the project details,
                                don't hesitate to ask. I will send the download
                                link. Or, if you are interested in employment or
                                some other partnership. Please contact me.
                            </p>
                            <a
                                className="contact-info"
                                href="https://github.com/Llewellin"
                                target="_blank"
                            >
                                <div className="ci-icon">
                                    {/*<img*/}
                                    {/*src={require('./img/contact-icons/1.png')}*/}
                                    {/*alt=""*/}
                                    {/*/>*/}
                                    <FontAwesomeIcon
                                        icon={faGithub}
                                        size="3x"
                                    />
                                </div>
                                <h6>Github</h6>
                            </a>
                            <a
                                className="contact-info"
                                href="https://www.linkedin.com/in/tingshenglee/"
                                target="_blank"
                            >
                                <div className="ci-icon">
                                    <FontAwesomeIcon
                                        icon={faLinkedin}
                                        size="3x"
                                    />
                                    {/*<img*/}
                                    {/*src={require('./img/contact-icons/1.png')}*/}
                                    {/*alt=""*/}
                                    {/*/>*/}
                                </div>
                                <h6>Linkedin</h6>
                            </a>
                            <a className="contact-info">
                                <div className="ci-icon">
                                    <FontAwesomeIcon icon={faSkype} size="3x" />
                                    {/*<img*/}
                                    {/*src={require('./img/contact-icons/2.png')}*/}
                                    {/*alt=""*/}
                                    {/*/>*/}
                                </div>
                                <h6>ensis_exsequens15</h6>
                            </a>
                            <div className="contact-info">
                                <div className="ci-icon">
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        size="3x"
                                    />

                                    {/*<img*/}
                                    {/*src={require('./img/contact-icons/3.png')}*/}
                                    {/*alt=""*/}
                                    {/*/>*/}
                                </div>
                                <h6>tingsheng.lee@gmail.com</h6>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h4 className="contact-title">Get in touch</h4>
                            <div className="contact-form">
                                <input type="text" placeholder="Name" />
                                <input type="email" placeholder="E-mail" />
                                <input type="text" placeholder="Subject" />
                                <textarea placeholder="Message" />
                                <button
                                    className="site-btn sb-c1"
                                    onClick={this.onSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14454.69575500059!2d121.58837075!3d25.07903825!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ac8a88548a33%3A0x151f9d6d9824c40a!2sBihu+Park!5e0!3m2!1sen!2stw!4v1547048410831"
                            // style="border:0"
                            allowFullScreen
                        />
                    </div>
                </div>
            </section>
        );
    }
}
