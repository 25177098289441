import { takeLatest, fork, call } from 'redux-saga/effects';

import { getLazy, signUpApi } from './api';
import { GET_LAZY, SIGN_UP } from './constants';

function* getLazySaga(): any {
    const response = yield call(getLazy);
    console.log('response = ', response);
}

function* signUp(): any {
    try {
        const response = yield call(signUpApi);
        console.log('response = ', response);
    } catch (err) {
        console.log('err = ', err);
    }
}

export default function*(): any {
    yield takeLatest(GET_LAZY, getLazySaga);
    yield takeLatest(SIGN_UP, signUp);
}
